import csv from 'csvtojson'
import AimpointStores from "./data/aimpoint-stores.json"
import PeopleData from "./data/people.csv"

async function loadPeoplePlaces () {
  const peopleDataRes = await fetch(PeopleData)
  const peopleData = await peopleDataRes.text()

  const headers = ['name', 'company', 'role', 'streetAddress', 'latlng', 'phone', 'email', 'birthdate', 'allabolag', 'upplysning', 'licenseplate', 'linkedin', 'socialmedia', 'kids', 'spouse']

  const rawData = await csv({
    headers, 
  }).fromString(peopleData)

  if (Object.keys(rawData[0]).length !== headers.length) throw Error("unexpected amount of columns in people data")

  const fixedData = rawData.map(row => ({
    ...row,
    lat: row.latlng.split(',')[0],
    lng: row.latlng.split(',')[1].replaceAll(' ', ''),
  }))

  return fixedData
}

function loadStorePlaces () {
  return AimpointStores.map(store => ({
    ...store,
    streetAddress: store["street address"],
    website: store.website && (store.website.startsWith('http://') || store.website.startsWith('https://')
      ? store.website : `https://${store.website}`),
    description: "Aimpoint reseller",
  }))
}

export async function loadPlaces () {
  const storePlaces = loadStorePlaces()
  const peoplePlaces = await loadPeoplePlaces()

  return [
    ...storePlaces,
    ...peoplePlaces,
  ]
}
